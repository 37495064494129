@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'manrope-700';
	src: url(assets/fonts/Manrope-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'manrope-500';
	src: url(assets/fonts/Manrope-Medium.ttf) format('truetype');
}

@font-face {
	font-family: 'manrope-400';
	src: url(assets/fonts/Manrope-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'mclaren';
	src: url(assets/fonts/McLaren-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'productsans-700';
	src: url(assets/fonts/ProductSans-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'productsans';
	src: url(assets/fonts/Product\ Sans\ Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'worksans';
	src: url(assets/fonts/Work\ Sans.ttf) format('truetype');
}

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.active-sidenav-link > svg > path{
	fill:black;
}

.inactive-sidenav-link > svg > path{
	fill:rgba(0,0,0,0.2);
}